import { navigate } from "gatsby";

import { contentPersonasBI } from "../content/contentPersonasBI";

const getContIDandName = (locn, branch, setContentID, setHesType, log, log2) => {
  // Gets contentID from branch object, referenced with values in locn object
  // NB: this is a function (not a React component) so it can't use hooks, so no useContext. Hence pass in the above params.

  0 && console.log(log, log2);
  log && console.log("getContIDandName.js locn=", locn);
  log2 && console.log("getContIDandName.js branch=", branch);

  let contIDTemp;
  let hesTypeTemp;

  const bailOnBadContentID = () => {
    contIDTemp = "BadContentID";
    hesTypeTemp = "Bad Path or ContentID";
    log && console.log("getContIDandName.js Bad Path or ContentID. Using contIDTemp='", contIDTemp, "' instead");
    navigate("/explore");
  };

  const topicObj = branch[locn.branch]?.topic[locn.topic];
  const subtopicObj = topicObj?.subtopic[locn.subtopic];
  log2 && console.log("getContIDandName.js topicObj=", topicObj);
  log2 && console.log("getContIDandName.js subtopicObj=", subtopicObj);

  if (subtopicObj?.contentID) {
    log2 && console.log("getContIDandName.js contentID found in branch.js =", subtopicObj.contentID);
  } else {
    log && console.log("getContIDandName.js Invalid contentID path in branch.js for locn=", locn);
    bailOnBadContentID();
  }

  contIDTemp = subtopicObj?.contentID;
  hesTypeTemp = subtopicObj?.subtopicNameAsTitle ? subtopicObj.subtopicNameAsTitle : topicObj.topicNameAsTitle;

  if (contentPersonasBI[contIDTemp]) {
    log2 && console.log("getContIDandName.js contentID also found in contentPersonasBI.");
  } else {
    log && console.log("getContIDandName.js But no contentID for'", contIDTemp, "' found in contentPersonasBI.");
    bailOnBadContentID();
  }

  setContentID(contIDTemp);
  setHesType(hesTypeTemp);

  return { contIDTemp, hesTypeTemp };
};

export default getContIDandName;
